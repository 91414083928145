<template>
  <div>
    <Swiper :swiperList="swiperList"></Swiper>
    <div class="category">
      <div>
        <img
          src="https://static.5t1800.com/s3/2023/05/08/645864b698242.png"
          alt=""
        />
        <div class="num">18年</div>
        <div class="txt">
          深耕化妆品、护肤领域18年<br />丰富的实体店运营经验沉淀
        </div>
      </div>
      <div>
        <img
          src="https://static.5t1800.com/s3/2023/05/08/645864b18a66a.png"
          alt=""
        />
        <div class="num">500+</div>
        <div class="txt">
          累计加盟门店数量超过500家<br />超市、社区、街铺、购物中心多渠道
        </div>
      </div>
      <div>
        <img
          src="https://static.5t1800.com/s3/2023/05/08/645864b44f481.png"
          alt=""
        />
        <div class="num">3</div>
        <div class="txt">天、地、人三网融合<br />玩转公域私域流量流量</div>
      </div>
      <div>
        <div style="padding: 8px 0">
          <img
            style="height: 52px"
            src="https://static.5t1800.com/s3/2023/05/08/645864b316e62.png"
            alt=""
          />
        </div>
        <div class="num">100万+</div>
        <div class="txt">
          累积服务会员超过100万人<br />产品、服务拥有良好口碑
        </div>
      </div>
    </div>
    <div class="homeTitle hidden-xs-only">创业很艰难，薇妮来分担</div>
    <div class="homeTitlePhone hidden-sm-and-up">创业很艰难，薇妮来分担</div>
    <div class="difficult flex a_c hidden-xs-only">
      <img
        src="https://static.5t1800.com/s3/2023/05/08/645870b673a6a.png"
        alt=""
      />
      <div class="difficultText">
        <div class="difficultTextTitle">你是否遇到下面的难题 ？</div>
        <div class="difficultTextCon">
          客源少，业绩不好做！<br />
          缺好产品、好项目，难吸引用户！<br />
          不懂专业、技术不好，用户易流失！<br />
          不会做活动，用户升单难！<br />
          不会管理，越忙越乱！<br />
          利润低，不赚钱！<br />
          ......
        </div>
      </div>
    </div>
    <div class="difficultPhone flex a_c hidden-sm-and-up">
      <img
        src="https://static.5t1800.com/s3/2023/05/08/645870b673a6a.png"
        alt=""
      />
      <div class="difficultText">
        <div class="difficultTextTitle">你是否遇到下面的难题 ？</div>
        <div class="difficultTextCon">
          客源少，业绩不好做！<br />
          缺好产品、好项目，难吸引用户！<br />
          不懂专业、技术不好，用户易流失！<br />
          不会做活动，用户升单难！<br />
          不会管理，越忙越乱！<br />
          利润低，不赚钱！<br />
          ......
        </div>
      </div>
    </div>
    <div class="homeTitle hidden-xs-only">门店经营难题 薇妮帮你解决</div>
    <div class="homeTitlePhone hidden-sm-and-up">门店经营难题 薇妮帮你解决</div>
    <img
      class="homeImage"
      v-for="(item, i) in imgShowHome"
      :key="i"
      :src="item"
      alt=""
    />
    <div class="contactBox hidden-xs-only">
      <div class="title">把握时代机遇 开创美丽事业</div>
      <div class="contactBoxBot flex a_c j_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/645890da5242b.png"
          alt=""
        />
        <div>
          <div class="phone">即刻拨打电话：025-86667555</div>
          <div class="huo">或</div>
          <div class="flex a_c">
            <div class="person">添加客户经理<br />企业微信沟通</div>
            <img
              class="code"
              src="https://static.5t1800.com/s3/2023/05/08/6458913c71c45.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="contactBoxPhone hidden-sm-and-up">
      <div class="title">把握时代机遇 开创美丽事业</div>
      <div class="contactBoxBot flex a_c j_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/645890da5242b.png"
          alt=""
        />
        <div>
          <div class="phone">即刻拨打电话：025-86667555</div>
          <div class="huo">或</div>
          <div>
            <div class="person">添加客户经理<br />企业微信沟通</div>
            <img
              class="code"
              src="https://static.5t1800.com/s3/2023/05/08/6458913c71c45.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "../../../components/swiper.vue";
export default {
  data() {
    return {
      swiperList: [
        {
          img: "https://static.5t1800.com/s3/2023/05/08/64586116cf16d.jpg",
          id: 306,
        },
      ],
      imgShowHome: [
        "https://static.5t1800.com/s3/2023/05/08/64588d2c78ede.jpg",
        "https://static.5t1800.com/s3/2023/05/08/64588d2f19779.jpg",
        "https://static.5t1800.com/s3/2023/05/08/64588d3289381.jpg",
        "https://static.5t1800.com/s3/2023/05/08/64588d3480d27.jpg",
        "https://static.5t1800.com/s3/2023/05/08/64588d37d5101.jpg",
        "https://static.5t1800.com/s3/2023/05/08/64588d3b3af00.jpg",
        "https://static.5t1800.com/s3/2023/05/08/64588d3e7b416.jpg",
        "https://static.5t1800.com/s3/2023/05/08/64588d416d85e.jpg",
      ],
    };
  },
  metaInfo: {
    title: "Vinistyle 薇妮", // set a title
    meta: [
      {
        name: "keyWords",
        content: "薇妮vinistyle,薇妮,薇妮美肤,薇尼美肤空间,护肤品,化妆品加盟,薇妮化妆品加盟,化妆品,薇妮化妆品,美白,防晒,防晒霜",
      },
      {
        name: "description",
        content: "vinistyle薇妮是江苏苏美国际旗下化妆品品牌，主营功效性护肤产品和解决方案，包括美容院专业线套组以及居家护肤产品，针对用户常见的斑、痘、敏、皱四大类肌肤问题，都有针对性的产品和解决方案。vinistyle薇妮提供整店输出、产品合作、项目合作等各种合作方式，诚邀美容院、皮肤管理工作、化妆品实体店加盟合作，共创美丽事业！",
      },
    ],
  },
  components: {
    Swiper,
  },
  created() {
    this.$store.commit("saveTypeIndex", 0);
  },
  mounted() {
    window.onresize = () => {
      this.autoHeight = (window.innerWidth * 600) / 1920 - 5 + "px";
    };
  },
  methods: {},
};
</script>

<style scoped>
.category {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 0;
  text-align: center;
}
.category > div {
  width: 300px;
  flex-shrink: 0;
  margin-bottom: 20px;
}
.category img {
  width: 68px;
  height: 68px;
  display: block;
  margin: 0 auto;
}
.category .num {
  font-size: 22px;
  font-weight: bold;
  color: #646464;
  padding: 14px 0;
}
.category .txt {
  font-size: 18px;
  font-weight: 300;
  color: #646464;
}

.homeTitle {
  height: 120px;
  background: #eeeeee;
  font-size: 35px;
  font-weight: bold;
  color: #8c8c8c;
  line-height: 120px;
  text-align: center;
}
.homeTitlePhone {
  height: 100px;
  background: #eeeeee;
  font-size: 26px;
  font-weight: bold;
  color: #8c8c8c;
  line-height: 100px;
  text-align: center;
}
.difficult {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 0;
  flex-wrap: wrap;
}
.difficult img {
  width: 507px;
  height: 507px;
  margin-right: 100px;
}
.difficult .difficultText {
  flex: 1;
  padding: 20px;
}
.difficult .difficultTextTitle {
  font-size: 43px;
  font-weight: bold;
  color: #9a7a7a;
  line-height: 79px;
}
.difficult .difficultTextCon {
  font-size: 30px;
  font-weight: bold;
  color: #7f8081;
  line-height: 50px;
}

.difficultPhone {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 0;
  flex-wrap: wrap;
}
.difficultPhone img {
  width: 100vw;
  height: 100vw;
  margin-right: 100px;
}
.difficultPhone .difficultText {
  flex: 1;
  padding: 12px;
}
.difficultPhone .difficultTextTitle {
  font-size: 30px;
  font-weight: bold;
  color: #9a7a7a;
  line-height: 79px;
}
.difficultPhone .difficultTextCon {
  font-size: 24px;
  font-weight: bold;
  color: #7f8081;
  line-height: 50px;
}

.homeImage {
  width: 100%;
  display: block;
}

.contactBox {
  background: #ededed;
  padding: 40px;
}
.contactBox .title {
  font-size: 66px;
  font-weight: bold;
  color: #ff5a5a;
  line-height: 82px;
  text-align: center;
}
.contactBox > div {
  max-width: 1200px;
  margin: 0 auto;
}
.contactBox .contactBoxBot {
  padding: 40px 0;
  flex-wrap: wrap;
}
.contactBox .contactBoxBot > img {
  width: 452px;
  height: 384px;
  margin-right: 70px;
}
.contactBox .phone {
  font-size: 45px;
  font-weight: bold;
  color: #754e31;
  line-height: 72px;
}
.contactBox .huo {
  font-size: 45px;
  font-weight: bold;
  color: #5e5e5e;
  line-height: 72px;
  text-align: center;
}
.contactBox .person {
  font-size: 58px;
  font-weight: bold;
  color: #754e31;
  line-height: 72px;
}
.contactBox .code {
  width: 179px;
  height: 179px;
  margin-left: 100px;
}

.contactBoxPhone {
  background: #ededed;
  padding: 16px;
}
.contactBoxPhone .title {
  font-size: 48px;
  font-weight: bold;
  color: #ff5a5a;
  line-height: 82px;
  text-align: center;
}
.contactBoxPhone > div {
  max-width: 1200px;
  margin: 0 auto;
}
.contactBoxPhone .contactBoxBot {
  padding: 40px 0;
  flex-wrap: wrap;
}
.contactBoxPhone .contactBoxBot > img {
  width: 100%;
}
.contactBoxPhone .phone {
  font-size: 24px;
  font-weight: bold;
  color: #754e31;
  line-height: 72px;
}
.contactBoxPhone .huo {
  font-size: 36px;
  font-weight: bold;
  color: #5e5e5e;
  line-height: 72px;
  text-align: center;
}
.contactBoxPhone .person {
  font-size: 24px;
  font-weight: bold;
  color: #754e31;
  line-height: 42px;
  text-align: center;
}
.contactBoxPhone .code {
  display: block;
  width: 179px;
  height: 179px;
  margin: 0 auto;
}
</style>

