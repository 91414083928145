<template>
  <div>
    <el-carousel :height="autoHeight">
      <el-carousel-item v-for="(item, i) in swiperList" :key="i">
        <img @click="toDetail(item.id)" :src="item.img" alt />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    swiperList: {
      type: Array,
      default: function() {
        return [
          "https://static.5t1800.com/s3/2023/05/08/64586116cf16d.jpg",
        ];
      }
    }
  },
  data() {
    return {
      //   screenWidth: document.body.clientWidth, // 屏幕宽度
      autoHeight: (window.innerWidth * 600) / 1920 - 5 + "px"
    };
  },
  mounted() {
    window.onresize = () => {
      this.autoHeight = (window.innerWidth * 600) / 1920 - 5 + "px";
    };
  },
  methods: {
    //跳转到banner详情
    toDetail() {
      // if (value) {
      //   var saveInfo = {
      //     id: value
      //   };
      //   storage.set("productDetails", saveInfo, true);
      //   this.routerTo("productDetails");
      // }
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    }
  },
  components: {}
};
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
img {
  width: 100%;
}
</style>
